/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fortawesome/fontawesome-free/css/all.css";

.username-inp ::placeholder {
  color: #212529 !important;
  opacity: 1;
  /* Firefox */
}

.username-inp ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #212529 !important;
}

.notification-container {
  width: 370px !important; /* Set your desired width */
  margin-left: auto;
  margin-right: auto;
}

.password-inp ::placeholder {
  color: #212529 !important;
  opacity: 1;
  /* Firefox */
}

.password-inp ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #212529 !important;
}

body {
  /* font-family: Tahoma, Helvetica, sans-serif !important; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  background-color: #ededed !important;
}

.clip {
  width: 100%;
  height: 16vh;
  /* background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%); */
  clip-path: polygon(
    84.055% 72.197%,
    84.055% 72.197%,
    86.147% 69.885%,
    88.005% 67.632%,
    89.628% 65.439%,
    91.016% 63.306%,
    92.169% 61.232%,
    93.086% 59.218%,
    93.767% 57.264%,
    94.211% 55.369%,
    94.418% 53.534%,
    94.388% 51.759%,
    94.388% 51.759%,
    94.171% 49.997%,
    93.807% 48.215%,
    93.298% 46.411%,
    92.642% 44.586%,
    91.841% 42.741%,
    90.894% 40.874%,
    89.802% 38.987%,
    88.563% 37.078%,
    87.18% 35.149%,
    85.651% 33.198%,
    85.651% 33.198%,
    84.007% 31.24%,
    82.279% 29.296%,
    80.466% 27.368%,
    78.569% 25.454%,
    76.586% 23.555%,
    74.519% 21.67%,
    72.366% 19.799%,
    70.128% 17.943%,
    67.805% 16.1%,
    65.397% 14.27%,
    65.397% 14.27%,
    62.976% 12.517%,
    60.604% 10.892%,
    58.282% 9.395%,
    56.011% 8.027%,
    53.789% 6.787%,
    51.618% 5.677%,
    49.498% 4.695%,
    47.429% 3.842%,
    45.411% 3.117%,
    43.443% 2.523%,
    43.443% 2.523%,
    41.562% 2.05%,
    39.812% 1.694%,
    38.192% 1.453%,
    36.703% 1.327%,
    35.344% 1.317%,
    34.115% 1.421%,
    33.017% 1.64%,
    32.048% 1.973%,
    31.21% 2.421%,
    30.501% 2.983%,
    30.501% 2.983%,
    29.838% 3.683%,
    29.126% 4.536%,
    28.363% 5.542%,
    27.552% 6.701%,
    26.691% 8.012%,
    25.78% 9.476%,
    24.821% 11.094%,
    23.811% 12.865%,
    22.753% 14.789%,
    21.645% 16.866%,
    21.645% 16.866%,
    20.561% 19.054%,
    19.565% 21.321%,
    18.657% 23.666%,
    17.837% 26.09%,
    17.107% 28.593%,
    16.465% 31.175%,
    15.913% 33.836%,
    15.45% 36.576%,
    15.078% 39.395%,
    14.796% 42.294%,
    14.796% 42.294%,
    14.587% 45.219%,
    14.444% 48.136%,
    14.367% 51.047%,
    14.356% 53.95%,
    14.412% 56.845%,
    14.534% 59.733%,
    14.722% 62.613%,
    14.978% 65.485%,
    15.3% 68.348%,
    15.688% 71.203%,
    15.688% 71.203%,
    16.163% 73.998%,
    16.751% 76.672%,
    17.455% 79.224%,
    18.272% 81.656%,
    19.205% 83.967%,
    20.252% 86.158%,
    21.415% 88.229%,
    22.692% 90.179%,
    24.085% 92.01%,
    25.592% 93.722%,
    25.592% 93.722%,
    27.198% 95.236%,
    28.894% 96.499%,
    30.68% 97.509%,
    32.556% 98.266%,
    34.522% 98.771%,
    36.578% 99.023%,
    38.723% 99.022%,
    40.957% 98.767%,
    43.28% 98.259%,
    45.692% 97.496%,
    45.692% 97.496%,
    48.051% 96.635%,
    50.205% 95.841%,
    52.154% 95.113%,
    53.898% 94.452%,
    55.437% 93.857%,
    56.771% 93.328%,
    57.901% 92.864%,
    58.825% 92.466%,
    59.544% 92.131%,
    60.059% 91.862%,
    60.059% 91.862%,
    60.5% 91.607%,
    61.01% 91.307%,
    61.589% 90.963%,
    62.237% 90.573%,
    62.953% 90.138%,
    63.738% 89.659%,
    64.591% 89.134%,
    65.512% 88.566%,
    66.502% 87.953%,
    67.56% 87.295%,
    67.56% 87.295%,
    68.703% 86.533%,
    69.959% 85.603%,
    71.328% 84.507%,
    72.81% 83.246%,
    74.404% 81.818%,
    76.111% 80.224%,
    77.929% 78.465%,
    79.859% 76.541%,
    81.901% 74.451%,
    84.055% 72.197%
  );
  border-radius: 20px;
}

.clip2 {
  width: 87%;
  height: 14vh;
  bottom: 13.8vh;
  left: 0.9vw;
  position: relative;
  /* background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%); */
  clip-path: polygon(
    96.85% 20.615%,
    96.85% 20.615%,
    95.756% 18.591%,
    94.507% 16.684%,
    93.103% 14.894%,
    91.544% 13.221%,
    89.828% 11.665%,
    87.958% 10.227%,
    85.932% 8.906%,
    83.751% 7.702%,
    81.414% 6.615%,
    78.922% 5.645%,
    78.922% 5.645%,
    76.362% 4.769%,
    73.838% 3.966%,
    71.346% 3.233%,
    68.889% 2.573%,
    66.464% 1.984%,
    64.073% 1.466%,
    61.714% 1.02%,
    59.388% 0.646%,
    57.095% 0.343%,
    54.833% 0.112%,
    49.102% 0.004%,
    49.102% 0.004%,
    47.246% 0.021%,
    45.251% 0.124%,
    43.118% 0.312%,
    40.846% 0.587%,
    38.434% 0.948%,
    35.883% 1.394%,
    33.193% 1.927%,
    30.362% 2.545%,
    27.391% 3.249%,
    24.279% 4.039%,
    24.279% 4.039%,
    21.212% 4.933%,
    18.349% 5.961%,
    15.69% 7.124%,
    13.236% 8.421%,
    10.986% 9.854%,
    8.94% 11.422%,
    7.099% 13.125%,
    5.462% 14.965%,
    4.03% 16.941%,
    2.802% 19.053%,
    2.802% 19.053%,
    1.794% 21.262%,
    1.01% 23.541%,
    0.45% 25.891%,
    0.113% 28.311%,
    -0% 30.801%,
    0.11% 33.362%,
    0.443% 35.993%,
    1% 38.694%,
    1.779% 41.465%,
    2.781% 44.307%,
    2.781% 44.307%,
    3.945% 47.177%,
    5.197% 50.034%,
    6.538% 52.878%,
    7.967% 55.709%,
    9.484% 58.526%,
    11.09% 61.329%,
    12.786% 64.118%,
    14.57% 66.894%,
    16.443% 69.656%,
    18.405% 72.403%,
    18.405% 72.403%,
    20.412% 75.087%,
    22.404% 77.647%,
    24.384% 80.081%,
    26.35% 82.391%,
    28.302% 84.577%,
    30.242% 86.638%,
    32.167% 88.575%,
    34.079% 90.388%,
    35.978% 92.078%,
    37.863% 93.643%,
    37.863% 93.643%,
    39.721% 95.054%,
    41.525% 96.293%,
    43.274% 97.36%,
    44.97% 98.255%,
    46.611% 98.978%,
    48.197% 99.528%,
    49.729% 99.905%,
    51.207% 100.11%,
    52.629% 100.141%,
    53.997% 100%,
    53.997% 100%,
    54.871% 99.804%,
    55.867% 99.464%,
    56.985% 98.979%,
    58.224% 98.349%,
    59.586% 97.575%,
    61.069% 96.657%,
    62.676% 95.594%,
    64.405% 94.387%,
    66.258% 93.035%,
    68.234% 91.539%,
    68.234% 91.539%,
    70.25% 89.915%,
    72.238% 88.182%,
    74.196% 86.34%,
    76.125% 84.387%,
    78.024% 82.323%,
    79.894% 80.149%,
    81.734% 77.864%,
    83.546% 75.468%,
    85.327% 72.961%,
    87.08% 70.342%,
    87.08% 70.342%,
    88.761% 67.676%,
    90.33% 65.018%,
    91.787% 62.366%,
    93.131% 59.721%,
    94.364% 57.083%,
    95.484% 54.452%,
    96.493% 51.829%,
    97.39% 49.212%,
    98.175% 46.604%,
    98.849% 44.003%,
    98.849% 44.003%,
    99.379% 41.432%,
    99.747% 38.913%,
    99.953% 36.446%,
    99.995% 34.03%,
    99.876% 31.666%,
    99.595% 29.354%,
    99.151% 27.092%,
    98.546% 24.882%,
    97.779% 22.723%,
    96.85% 20.615%
  );
  border-radius: 20px;
}

.news-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.in-play-effect {
  color: #508d0e;
  transition: 0.4s ease-in;
  animation: 1s infinite effect;
  font-weight: 700;
}

.icon-sports::before {
  content: "";
  position: absolute;
  top: -4.26667vw;
  width: 100%;
  left: 0;
  height: 17.53333vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
  z-index: -1;
  display: block;
  background: linear-gradient(-180deg, #243a48 20%, #172732 91%);
  clip-path: polygon(
    99.973% 24.242%,
    100% 24.242%,
    100% 100%,
    0% 100%,
    0% 24.242%,
    0.027% 24.242%,
    0.027% 24.242%,
    0.885% 23.318%,
    2.866% 21.335%,
    5.893% 18.565%,
    9.891% 15.281%,
    14.783% 11.756%,
    20.494% 8.261%,
    26.947% 5.068%,
    34.066% 2.451%,
    41.776% 0.681%,
    50% 0.03%,
    50% 12.152%,
    50% 0.03%,
    50% 0.03%,
    58.224% 0.681%,
    65.934% 2.451%,
    73.053% 5.068%,
    79.506% 8.261%,
    85.217% 11.756%,
    90.109% 15.281%,
    94.107% 18.565%,
    97.134% 21.335%,
    99.115% 23.318%,
    99.973% 24.242%
  );
}

.icon-sports-active::before {
  content: "";
  position: absolute;
  top: -4.26667vw;
  width: 100%;
  left: 0;
  height: 17.53333vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
  z-index: -1;
  display: block;
  background: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
  clip-path: polygon(
    99.973% 24.242%,
    100% 24.242%,
    100% 100%,
    0% 100%,
    0% 24.242%,
    0.027% 24.242%,
    0.027% 24.242%,
    0.885% 23.318%,
    2.866% 21.335%,
    5.893% 18.565%,
    9.891% 15.281%,
    14.783% 11.756%,
    20.494% 8.261%,
    26.947% 5.068%,
    34.066% 2.451%,
    41.776% 0.681%,
    50% 0.03%,
    50% 12.152%,
    50% 0.03%,
    50% 0.03%,
    58.224% 0.681%,
    65.934% 2.451%,
    73.053% 5.068%,
    79.506% 8.261%,
    85.217% 11.756%,
    90.109% 15.281%,
    94.107% 18.565%,
    97.134% 21.335%,
    99.115% 23.318%,
    99.973% 24.242%
  );
}

.multimarket-heading::before {
  content: "";
  width: 35px;
  height: 35px;
  background: url("https://aura444.com/icon-nodata.869cb165d644b016.svg") 0 0 /
    contain no-repeat;
  margin-right: 1.33333vw;
}

.line::before {
  margin: 0 10px;
  content: "|";
}

.age-model.ant-modal .ant-modal-content {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  padding: 1rem;
  border-radius: 0px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-family: Tahoma, Helvetica, sans-serif !important;
}

.age-model.ant-modal .ant-modal-close {
  background: green;
  width: 27px;
  height: 27px;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  /* position: absolute;
    right: -12px;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    background: green;
    color: black;
    border-radius: 50%;
    margin-top: -13px;
    z-index: 2;
    cursor: pointer;
    display: flex; */
}

.login-model.ant-modal {
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100vw;
}

.login-model.ant-modal-root .ant-modal {
  max-width: 100vw !important;
  width: 100% !important;
}

.login-model.ant-modal-root .ant-modal-centered .ant-modal {
  width: 100% !important;
}

@media (max-width: 767px) {
  .login-model.ant-modal-root .ant-modal {
    max-width: 100vw !important;
    /* width: 100% !important; */
  }
}

.password-model.ant-modal .ant-modal-header {
  color: white !important;
  font-weight: 700;
  font-size: 0.875rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-image: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  display: flex;
  margin-bottom: 0px;
}

.password-model.ant-modal .ant-modal-footer {
  margin-top: 0;
}

/* 
.password-model .ant-btn-default {
    font-size: .875rem;
    border-radius: .25rem;
    border-color: unset;
    background: linear-gradient(180deg, #2A3A43 27%, #1C282D 83%);
} */

.rollingcommission-model.ant-modal .ant-modal-header {
  color: white !important;
  font-weight: 700;
  font-size: 0.875rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-image: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  display: flex;
  margin-bottom: 0px;
}

.password-model.ant-modal .ant-modal-title {
  color: white;
  line-height: 1.5;
  font-weight: 700;
  font-size: 0.938rem;
  margin-bottom: 0px;
}

.rollingcommission-model.ant-modal .ant-modal-title {
  color: white;
  line-height: 1.5;
  font-weight: 700;
  font-size: 0.938rem;
  margin-bottom: 0px;
}

.rollingcommission-model.ant-modal .ant-modal-close {
  top: 2px;
  inset-inline-end: 0px;
  color: white;
  font-size: 1.3125rem;
  font-weight: 700;
}

.password-model.ant-modal .ant-modal-content {
  padding: 0 !important;
  font-family: Tahoma, Helvetica, sans-serif !important;
  border-radius: 0.5rem !important;
  /* width: 500px; */
}

.password-model.ant-modal .ant-modal-close {
  display: none;
}

.rollingcommission-model.ant-modal .ant-modal-content {
  padding: 0 !important;
  font-family: Tahoma, Helvetica, sans-serif !important;
  border-radius: 0.5rem !important;
  /* width: 500px; */
}

:where(.css-dev-only-do-not-override-mzwlov).ant-input-affix-wrapper {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #5c6873;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined:hover {
  border-color: #dee2e6;
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined:focus-within {
  border-color: #000000;
  box-shadow: none;
  outline: 0;
  background-color: #ffffff;
}

.login-model.ant-modal .ant-modal-content {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  padding: 10px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-family: Tahoma, Helvetica, sans-serif !important;
  height: 100vh;
}

.login-model.ant-modal .ant-modal-close {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  font-size: 1.313rem;
  color: black;
  border-radius: 9px;
}

.dropdown-li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding: 2px;
  transition: transform 0.3s;
  position: absolute;
  background-position: 100% -1000px;
  height: 17px;
  width: 17px;
  background-repeat: no-repeat;
  line-height: 13px;
  border-radius: 4px;
  right: 6.5px;
  color: #83ae17;
  border: 1px solid #83ae17;
}

@media screen and (min-aspect-ratio: 16 / 9) {
  .loading-wrap {
    width: 9.48876vmax;
    height: 5.49325vmax;
    font-size: 0.7991vmax;
    box-shadow: 0 0.44978vmax 1.49925vmax 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.74963vmax;
    padding: 1.04948vmax 0;
  }
}

.loading-wrap {
  z-index: 9999999 !important;
  position: fixed;
  background: #fff;
  color: #243a48;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
}

.loading-wrap {
  display: flex;
  top: 35%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-aspect-ratio: 16 / 9) {
  .loading {
    width: 5vmax;
    height: 2.24738vmax;
  }

  .loading-circle {
    width: 5vmax;
    height: 2.24738vmax;
  }
}

.loading-circle {
  padding: 1% 0;
}

.loading {
  position: relative;
  padding: 1% 0;
}

.loading > div:first-of-type {
  left: 0;
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  animation: loading-1 0.7s ease infinite;
  z-index: 5;
}

.loading > div {
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 50%;
}

.loading > div:nth-of-type(2) {
  left: 50%;
  background: #161616;
  animation: loading-2 0.7s ease infinite;
}

.loading > div {
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 50%;
}

.loading-wrap p {
  flex: 0 0 100%;
  text-align: center;
  margin: 0;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-popover {
  --antd-arrow-background-color: #000000 !important;
  color: #ffffff !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-popover .ant-popover-inner {
  background-color: #000000 !important;
  padding-top: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-popover
  .ant-popover-inner-content {
  color: #ffffff !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-popover .ant-popover-content {
  /* top: 7px !important; */
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  cursor: pointer;
  position: relative;
  padding-right: 26px;
  padding: 10px !important;
  font-weight: 700;
  background: #e0e6e6 !important;
  color: #000000 !important;
  box-shadow: inset 0 0 0 9999px transparent;
  text-align: center !important;
  min-width: 99px;
  vertical-align: middle;
}

.activity-log.ant-table-wrapper .ant-table-thead > tr > th,
.activity-log.ant-table-wrapper .ant-table-thead > tr > td {
  border: 0.1px solid #c8ced3 !important;
  cursor: pointer;
  position: relative;
  padding-right: 26px;
  padding: 10px !important;
  font-weight: 700;
  background: #e0e6e6 !important;
  color: #000000 !important;
  box-shadow: inset 0 0 0 9999px transparent;
  text-align: start !important;
  min-width: 99px;
  vertical-align: middle;
}

.bets-history.ant-table-wrapper .ant-table-thead > tr > th,
.bets-history.ant-table-wrapper .ant-table-thead > tr > td {
  border: 0.1px solid #c8ced3 !important;
  cursor: pointer;
  position: relative;
  padding-right: 26px;
  padding: 10px !important;
  font-weight: 700;
  background: #e0e6e6 !important;
  color: #000000 !important;
  box-shadow: inset 0 0 0 9999px transparent;
  text-align: center !important;
  min-width: 140px;
  vertical-align: middle;
}

.account-statement.ant-table-wrapper .ant-table-thead > tr > th,
.account-statement.ant-table-wrapper .ant-table-thead > tr > td {
  border: 0.1px solid #c8ced3 !important;
  cursor: pointer;
  position: relative;
  padding-right: 26px;
  padding: 10px !important;
  font-weight: 700;
  background: #e0e6e6 !important;
  color: #000000 !important;
  box-shadow: inset 0 0 0 9999px transparent;
  text-align: center !important;
  vertical-align: middle;
}

.account-statement.ant-table-wrapper table {
  margin-bottom: 7px;
  border: 1px solid #c8ced3 !important;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  color: #212529;
  vertical-align: top;
  border-radius: 0 !important;
}

.profit-loss.ant-table-wrapper .ant-table-thead > tr > th,
.profit-loss.ant-table-wrapper .ant-table-thead > tr > td {
  border: 0.1px solid #c8ced3 !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-empty-normal .ant-empty-image {
  display: none;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder {
  color: black;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0px;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper table {
  margin-bottom: 7px;
  width: 110%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  color: #212529;
  vertical-align: top;
  border-radius: 0 !important;
}

.profit-loss.ant-table-wrapper table {
  margin-bottom: 7px;
  border: 1px solid #c8ced3 !important;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  color: #212529;
  vertical-align: top;
  border-radius: 0 !important;
}

.m-profit-loss.ant-table-wrapper table {
  margin-bottom: 7px;
  border: 1px solid #c8ced3 !important;
  width: 140%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  color: #212529;
  vertical-align: top;
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  padding: 8px 10px;
  text-align: center !important;
}

.activity-log.ant-table-wrapper .ant-table-tbody > tr > th,
.activity-log.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 10px;
  text-align: start !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0px;
}

.activity-log.ant-table-wrapper .ant-table-tbody > tr > th,
.activity-log.ant-table-wrapper .ant-table-tbody > tr > td {
  vertical-align: top;
}

.loadingBalance {
  padding: 20px;
  box-sizing: border-box;
  width: 120px;
  color: white;
  font-size: 12px;
  font-weight: 700;
}

.loadingBalance span {
  display: inline-block;
  vertical-align: middle;
  width: 0.6em;
  height: 0.6em;
  margin: 0.19em;
  background: #ffffff;
  border-radius: 0.6em;
  opacity: 0;
  animation: loadingBalance 0.4s infinite alternate;
}

.loadingBalance span:first-child {
  animation-delay: 0.2s;
}

.loadingBalance span:nth-child(2) {
  animation-delay: 0.3s;
}

.loadingBalance span:nth-child(3) {
  animation-delay: 0.4s;
}

.loadingBalance span:nth-child(4) {
  animation-delay: 0.5s;
}

.loadingBalance span:nth-child(5) {
  animation-delay: 0.6s;
}

.loadingBalance span:nth-child(6) {
  animation-delay: 0.7s;
}

.loadingBalance span:nth-child(7) {
  animation-delay: 0.8s;
}

.openbets-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 !important;
  max-width: 100vw !important;
  width: 100%;
}

.openbets-modal.ant-modal .ant-modal-content {
  border-radius: 0;
  padding: 0;
}

.openbets-modal.ant-modal.ant-modal .ant-modal-header {
  border-radius: 0;
  height: 40px;
  display: flex;
  vertical-align: middle;
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  color: #000000;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
}

.openbets-modal.ant-modal .ant-modal-title {
  margin-left: 13px;
  line-height: 23px;
  font-weight: 700;
  font-size: 16px;
}

.openbets-modal.ant-modal .ant-modal-close {
  top: 5px;
  position: fixed;
  z-index: 99999;
}

.openbets-modal.ant-modal .ant-modal-body {
  position: relative;
  top: 50px;
}

details > summary {
  list-style: none;
}

details>summary::marker,
/* Latest Chrome, Edge, Firefox */
details>summary::-webkit-details-marker

/* Safari */ {
  display: none;
}

.rules-modal.ant-modal .ant-modal-close {
  display: none;
}

.rules-modal.ant-modal .ant-modal-content {
  padding: 8px 5px;
  line-height: 18px;
}

.rules-modal.ant-modal .ant-modal-body {
  overflow: hidden;
  overflow-y: auto;
  height: calc(84vh - 100px);
}

.back-1.spark,
.back-gradient .spark,
.lay-gradient .spark,
.lottery-ball.spark {
  animation: 0.6s sparkBack;
}

@keyframes sparkBack {
  0%,
  100% {
    background-color: #72bbef;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }
}

.back-2.spark {
  animation: 0.6s sparkBack_2;
}

@keyframes sparkBack_2 {
  0%,
  100% {
    background-color: #b7d5eb;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }
}

.back-3.spark {
  animation: 0.6s sparkBack_3;
}

@keyframes sparkBack_3 {
  0%,
  100% {
    background-color: #d7e8f4;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }
}

.lay-1.spark,
.lay-gradient .spark {
  animation: 0.6s sparkLay;
}

@keyframes sparkLay {
  0%,
  100% {
    background-color: #faa9ba;
    color: #1e1e1e;
  }

  50% {
    background-color: #26f1f8;
    color: #fff;
  }
}

.lay-2.spark {
  animation: 0.6s sparkLay_2;
}

@keyframes sparkLay_2 {
  0%,
  100% {
    background-color: #efd3d9;
    color: #1e1e1e;
  }

  50% {
    background-color: #26f1f8;
    color: #fff;
  }
}

.lay-3.spark {
  animation: 0.6s sparkLay_3;
}

@keyframes sparkLay_3 {
  0%,
  100% {
    background-color: #f6e6ea;
    color: #1e1e1e;
  }

  50% {
    background-color: #26f1f8;
    color: #fff;
  }
}

@keyframes effect {
  0% {
    transform: scale(1);
    color: #508d0e;
  }

  50% {
    transform: scale(1);
    color: #508d0e;
  }

  100% {
    transform: scale(1);
    color: red;
  }
}

/* 
@keyframes loading-1 {
    0% {
        left: 0;
    }

    50% {
        left: 50%;
    }

    100% {
        left: 0;
    }
}

@keyframes loading-2 {
    0% {
        left: 50%;
        z-index: 1;
    }

    49% {
        z-index: 1;
    }

    50% {
        left: 0;
        z-index: 10;
    }

    100% {
        left: 50%;
        z-index: 10;
    }
} */

.circle {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #3498db;
}

.circle1 {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  animation: loading-1 1.2s infinite;
}

.circle2 {
  background: #161616;
  animation: loading-2 1.2s infinite;
}

@keyframes loading-1 {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes loading-2 {
  0% {
    left: 100%;
    z-index: 1;
  }

  50% {
    left: 0;
    z-index: 10;
  }

  100% {
    left: 100%;
    z-index: 10;
  }
}

@keyframes loadingBalance {
  0% {
    width: 0.2em;
    height: 0.2em;
    opacity: 0;
  }

  49% {
    width: 0.4em;
    height: 0.4em;
    opacity: 0.5;
  }

  100% {
    width: 0.6em;
    height: 0.6em;
    opacity: 1;
  }
}

.market-exposure[data-value],
.win[data-value] {
  color: green !important;
  fill: green;
}

.market-exposure[data-value^="-"],
.win[data-value^="-"] {
  color: red !important;
  fill: red;
}

.book-model.ant-modal .ant-modal-content {
  padding: 0 !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.book-model.ant-modal .ant-modal-header {
  color: white !important;
  font-weight: 700;
  font-size: 0.875rem;
  background-image: linear-gradient(-180deg, #f6a21e 0%, #cb7e05 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  display: flex;
  margin-bottom: 0px;
  padding: 4px 15px !important;
}

.book-model.ant-modal .ant-modal-close {
  top: 0px;
  inset-inline-end: 0px;
  color: white;
  font-size: 1.3125rem;
  font-weight: 700;
}

.book-model.ant-modal .ant-modal-title {
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0px;
}

.ant-table-wrapper .ant-table-container {
  width: 100%;
  overflow: auto;
}

/* 

.suspended.bets-selections:after,
.suspended .odd_even_clr:after {
    content: attr(data-title);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 35%;
    background: #000;
    color: #fff;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    text-transform: capitalize;
    top: 0;
    position: absolute;
    right: 0;
}

.suspended-pr.bets-selections:after,
.suspended.bets-selections:after,
.suspended .odd_even_clr:after {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px !important;
    text-shadow: 0 .26667vw 1.06667vw rgb(0 0 0 / 50%);
    font-weight: 900;
    opacity: .4;
} */

.timer-overlay {
  position: absolute;
  right: 4px;
  height: auto;
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  align-items: center;
}

.timer-group {
  height: 50px;
  margin: 5px auto 0;
  position: relative;
  width: 50px;
}

.timerNumber {
  font-size: 30px;
  color: #ff2800;
  font-weight: 700;
}

.timer-group {
  height: 50px;
  margin: 5px auto 0;
  position: relative;
  width: 50px;
}

.timer {
  border-radius: 50%;
  height: 50px;
  overflow: hidden;
  position: absolute;
  width: 50px;
}

.timer:after {
  background: rgb(17, 17, 17);
  border-radius: 50%;
  content: "";
  display: block;
  height: 80px;
  left: 10px;
  position: absolute;
  width: 80px;
  top: 10px;
}

.timer .hand {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 50%;
}

.timer .hand span {
  border: 50px solid rgba(0, 255, 255, 0.4);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  display: block;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(225deg);
  width: 0;
}

.timer .hand:first-child {
  transform: rotate(180deg);
}

.timer .hand span {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.timer .hand:first-child span {
  animation-name: spin1;
}

.timer .hand:last-child span {
  animation-name: spin2;
}

.timer.minute {
  background: rgba(0, 0, 0, 0.2);
  height: 50px;
  right: 0;
  width: 50px;
  bottom: 0;
}

.timer.minute .hand span {
  animation-duration: 45s;
  border-top-color: green;
  border-right-color: green;
  border-width: 25px;
}

.spanColorRed {
  border-top-color: red !important;
  border-right-color: red !important;
}

.timer.minute:after {
  height: 40px;
  left: 5px;
  width: 40px;
  top: 5px;
}

.face {
  border-radius: 50%;
  height: 50px;
  left: 0;
  position: absolute;
  width: 50px;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.face h2 {
  font-weight: 300;
}

.face p {
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  text-align: center;
  color: #fff;
}

@keyframes spin1 {
  0% {
    transform: rotate(225deg);
  }

  50% {
    transform: rotate(225deg);
  }

  to {
    transform: rotate(405deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(225deg);
  }

  50% {
    transform: rotate(405deg);
  }

  to {
    transform: rotate(405deg);
  }
}

.tbl-bets .odd-row {
  border-bottom: unset;
}

.playerABgTie {
  background: linear-gradient(
    90deg,
    rgb(153 199 241) 0%,
    rgb(138 189 216 / 50%) 49%,
    rgb(146 198 246) 100%
  );
  padding-top: 0.1rem;
  padding-bottom: 0.7rem;
}

.suspended-casino {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: 700;
  top: 0;
  display: flex;
  text-align: center;
  opacity: 0.7 !important;
  z-index: 1;
  border-radius: 0 0 5px 5px;
  color: #ca1010;
  background: #fff;
  border: 2px solid #ca1010;
}

.stats-text {
  text-transform: uppercase;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex: auto;
}

.recent-result-modal.ant-modal .ant-modal-close {
  display: none !important;
}

.recent-result-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
}

.winner-icon {
  animation: 1.5s infinite winnerbox;
  border-radius: 50%;
  color: green;
  margin-left: 10px;
  font-size: 14px;
}

.winner-label i {
  font-size: 26px;
  box-shadow: 0 0 #2c3e50;
  animation: winnerbox 1.5s infinite;
  border-radius: 50%;
}

.loser-icon {
  animation: 1.5s infinite winnerbox;
  border-radius: 50%;
  color: red;
  transform: rotate(180deg);
  margin-left: 10px;
  font-size: 14px;
}

@keyframes winnerbox {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(29, 127, 30, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(29, 127, 30, 0);
  }

  to {
    -webkit-box-shadow: 0 0 0 0 rgba(29, 127, 30, 0);
  }
}

.pushable {
  background: hsl(340deg 100% 32%);
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 2px;
}

.front {
  display: block;
  padding: 7px 42px;
  border-radius: 8px;
  font-size: 1.25rem;
  background: hsl(345deg 100% 47%);
  color: white;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.suspendedandar::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  right: 0;
  background-color: #0009;
  color: #fff;
  text-transform: uppercase;
  font: 900 22px / 1 FontAwesome;
  content: "\f023";
  top: 0;
}

/* 
.suspended::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 0;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    text-transform: uppercase;
    font-family: "Font Awesome 5 Free";
    content: "\f023";
    font-weight: 900;
    font-size: 18px;
    top: 0;
    z-index: 10;
    width: 100%;
} */

.suspended-card::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-transform: uppercase;
  font-family: "Font Awesome 5 Free";
  content: "\f023";
  font-weight: 900;
  font-size: 18px;
  top: 0;
  z-index: 10;
  width: 100%;
}

.suspended-toss::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  background: #243a4866;
  color: #ffffff;
  /* font-family: "Font Awesome 5 Free"; */
  content: "SUSPENDED";
  /* font-weight: 900; */
  font-size: 12px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 10;
  width: 100%;
}

.suspended-match::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  background: #e0e6e9a3;
  color: #ffffff;
  /* font-family: "Font Awesome 5 Free"; */
  content: "SUSPENDED";
  /* font-weight: 900; */
  color: rgb(153, 10, 10);
  font-size: 32px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 10;
  width: 100%;
  border: 2px solid rgb(153, 10, 10);
  font-weight: bold;
}

/* casino  */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .baccarat .baccarat-bets {
    padding: 0 5px;
    margin-top: 5px;
    height: 110px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets {
    padding: 0 5px;
    margin-top: 20px;
    height: 100px;
  }
}

.baccarat .baccarat-bets {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  margin-top: 5px;
  position: relative;
  padding-bottom: 20px;
}

.baccarat .baccarat-bets {
  padding: 0 5px;
  margin-top: 5px;
  height: 100px;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .banker,
  .baccarat .player {
    width: 50% !important;
    height: 90px !important;
  }
}

.baccarat .player {
  width: 50%;
  height: 120px;
  position: relative;
}

.player {
  color: #4694de;
}

.suspended {
  position: relative;
  overflow: hidden;
}

.suspendedWrite {
  position: relative;
}

.baccarat .player .baccarat-bets-name {
  background: linear-gradient(-180deg, #1285e2 0, #0b5795 100%);
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  align-items: flex-start;
  padding-left: 20px !important;
  text-decoration: none;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets-name {
    height: 100%;
    font-size: 10px;
    padding-left: 0;
  }
}

.baccarat .baccarat-bets-name {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  padding-left: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}

.player {
  color: #4694de;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets-name div {
    line-height: 14px !important;
    margin-bottom: 2px !important;
    font-size: 15px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets-name div {
    line-height: 14px !important;
    margin-bottom: 2px !important;
  }
}

.title,
.baccarat .baccarat-bets-name div {
  font-size: 12px;
  font-weight: 700;
}

.profitLoss {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .baccarat .tie {
    height: 90px !important;
    width: 90px !important;
    top: -95px !important;
  }
  .baccarat .luckycard {
    height: 90px !important;
    width: 90px !important;
    top: -95px !important;
  }
}

.baccarat .tie {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%);
  top: -105px;
  z-index: 15;
}

.baccarat .luckycard {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%);
  top: -105px;
  z-index: 15;
}

.baccarat .tie .baccarat-bets-name {
  background: linear-gradient(-180deg, #11b24b 0, #1c6235 100%);
  border-radius: 50%;
  border: 2px solid #000;
}

.baccarat .luckycard .baccarat-bets-name {
  background-image: url("https://casino.gamaexchange.com/assets/lucky7_cards/7.png");
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets-name {
    height: 100%;
    font-size: 10px;
    padding-left: 0;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .banker,
  .baccarat .player {
    width: 50% !important;
    height: 90px !important;
  }
}

.baccarat .banker {
  width: 50%;
  height: 120px;
  position: relative;
}

.banker {
  color: #f10c0c;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .banker .baccarat-bets-name {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-right: 5px;
  }
}

.baccarat .banker .baccarat-bets-name {
  background: linear-gradient(-180deg, #af2736 0, #93212d 100%);
  align-items: flex-end;
  padding-right: 20px !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .baccarat-bets-name {
    height: 100%;
    font-size: 10px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1320px) {
  .show-xs {
    display: block;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat-bets {
    height: auto !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .player-pair {
    width: 50%;
    height: 70px;
    margin: 0 10px 0 0;
  }

  .baccarat .banker-pair {
    width: 50%;
    height: 70px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .player-pair {
    width: 50%;
    height: 60px;
    margin: 0 10px 0 0;
  }

  .baccarat .banker-pair {
    width: 50%;
    height: 60px;
    margin: 0 10px 0 0;
  }
}

.baccarat .player-pair {
  margin-right: 2px;
  width: 100%;
  height: 70px;
  margin-top: 15px;
  position: relative;
}

.baccarat .banker-pair {
  margin-right: 2px;
  width: 100%;
  height: 70px;
  margin-top: 15px;
  position: relative;
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .player-pair .baccarat-bets-name {
    border-radius: 4px 0 0 4px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .player-pair .baccarat-bets-name {
    background: linear-gradient(-180deg, #af2736 0, #93212d 100%);
  }
}

.baccarat .banker-pair .baccarat-bets-name {
  border-radius: 0 16px 16px 0;
  background: linear-gradient(-180deg, #1285e2 0, #0b5795 100%);
}
.player-pair .baccarat-bets-name {
  border-radius: 16px 0 0 16px;
  background: linear-gradient(-180deg, #af2736 0, #93212d 100%);
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .banker-pair .baccarat-bets-name {
    background: linear-gradient(-180deg, #1285e2 0, #0b5795 100%);
  }
}

@media only screen and (min-width: 280px) and (max-width: 1320px) {
  .baccarat .banker-pair .baccarat-bets-name {
    border-radius: 0 4px 4px 0;
  }
}

.function_zone,
.function_zone_many {
  white-space: nowrap;
  overflow-y: hidden;
  width: 100%;
  overflow-x: scroll;
  display: inline-block;
  justify-content: center;
  padding: 0 4px;
}

.baccarat .player.suspended:after {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  padding-left: 5px;
}

.suspended:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  /* background-color: #0009; */
  background-color: #00000094;
  color: var(--text-white-color);
  text-transform: uppercase;
  font-family: "Font Awesome 5 Free";
  content: "\f023";
  font-weight: 900;
  font-size: 18px;
  top: 0;
  z-index: 10;
  width: 100%;
  color: #fff;
}

.suspendedWrite:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  background-color: #000000ba;
  color: #ca1010;
  text-transform: uppercase;
  content: "SUSPENDED";
  font-size: 22px;
  top: 0;
  z-index: 10;
  width: 100%;
  border: 2px solid;
  opacity: 0.8;
  font-weight: bold;
}

.function_zone .chips {
  position: relative;
  left: 0;
  float: unset;
}

.function_zone,
.function_zone_many {
  width: 100%;
  height: 100px;
  line-height: 43px;
  z-index: 1;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .chips {
    left: 0;
    height: 56px;
    padding: 0;
    margin: 0;
  }
}

.chips {
  height: 56px;
  width: 35px;
  position: relative;
  padding: 5px;
  /* margin-left: 14px; */
  display: inline;
}

.chips li.now .ring {
  border: 4px solid #fd8f3b;
  transition: all 0.15s linear;
  display: revert;
  top: 55px;
}

.chips li:not(:hover) {
  transition: all 0.15s linear;
}

.chips li.now {
  height: 90px;
  top: -7px;
  transition: all 0.15s linear;
}

@media only screen and (max-width: 992px) {
  .chips li {
    width: 45px;
    height: 4px;
    margin-right: 8px;
  }
}

.chips li {
  width: 55px;
  height: 75px;
  position: relative;
  top: 0;
  list-style: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .chips li p,
  .chips li img {
    width: 50px;
    height: 62px;
    font-size: 10px;
  }
}

.chips li p,
.chips li img {
  width: 55px;
  height: 100px;
  text-align: center;
  line-height: 95px;
  font-size: 14px;
  color: #272727;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-size: 100% 100%;
  font-size: 12px;
}

.chips li img {
  filter: drop-shadow(0 0 0.25rem #fd8f3b);
}

@media only screen and (max-width: 992px) {
  .chips li .ring {
    position: absolute;
    width: 46px;
    height: 33px;
    border-radius: 100px / 85px;
    bottom: 12px;
    left: calc(50% - 20px);
  }
}

.chips li .ring {
  position: absolute;
  width: 50px;
  height: 40px;
  border: 0 solid #fd8f3b;
  border-radius: 100px / 76px;
  bottom: 0;
  left: calc(50% - 25px);
  font-size: 0;
  text-indent: -99999px;
}

.playerA {
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .playerA,
  .playerB {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}

.playerA span,
.playerB span {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%) !important;
}

.win-mark-con {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .win-mark-con {
    width: 100%;
    height: 112px;
  }
}

.win-mark-con {
  width: 100%;
  height: 123px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: #32325d40 0 6px 12px -2px, #0000004d 0 3px 7px -3px;
}

.market-name-section {
  top: 5px;
  border-top-left-radius: 90px;
  border-bottom-right-radius: 90px;
  background-color: #fff !important;
  height: 42px;
  box-shadow: #0006 0 2px 4px, #0000004d 0 7px 13px -3px, #0003 0 -3px inset;
  color: #000;
  width: 95%;
  height: 26px;
  top: 4px;
}

@media only screen and (max-width: 767px) {
  .poker-suspnd {
    border-top-left-radius: 50px;
    border-bottom-right-radius: 73px;
  }
}

.suspended-casino {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: 700;
  top: 0;
  display: flex;
  text-align: center;
  opacity: 0.7 !important;
  z-index: 1;
  border-radius: 0 0 5px 5px;
  color: #ca1010;
  background: #fff;
  border: 2px solid #ca1010;
}

.inside-back {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%) !important;
  margin-left: 26px;
  margin-right: 26px;
  transform: skew(-31deg);
  height: 50px;
}

.inside-back a {
  transform: skew(31deg) !important;
}

.poker-suspnd {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 73px;
}

.back-1-poker {
  border-top-left-radius: 90px;
  border-bottom-right-radius: 90px;
  background-color: #fff !important;
  border-left-color: #fff;
  width: 180px;
  height: 50px;
  text-align: center;
  box-shadow: #0006 0 2px 4px, #0000004d 0 7px 13px -3px, #0003 0 -3px inset;
}

@media only screen and (max-width: 375px) {
  .back-1-poker {
    width: 161px;
  }
}

.marketpoker {
  width: 100%;
  padding: 0 20px;
  margin: 0;
  height: 115px;
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 16px;
  /* box-shadow: 0 15px 30px #0000001a; */
  backdrop-filter: blur(1.9px);
  -webkit-backdrop-filter: blur(1.9px);
  border: 1px solid rgba(255, 255, 255, 0.31);
}

@media only screen and (max-width: 425px) {
  .profitloss {
    left: 17%;
  }
}

.profitloss {
  position: absolute;
  bottom: -1px;
  left: 21%;
}

@media only screen and (max-width: 767px) {
  .playerA,
  .playerB {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}

@keyframes exampleAnimation {
  0% {
    transform: scale(1.1);
    color: #ff0 !important;
  }
  50% {
    transform: scale(0.8);
    color: red !important;
  }
  100% {
    transform: scale(1.1);
    color: #ff0 !important;
  }
}

.vimaanClass {
  animation: exampleAnimation 1s infinite;
}
.casinoFont {
  font-family: Roboto Condensed, sans-serif;
  min-height: 20px;
  line-height: 13px;
}

.new-card-flex {
  display: flex;
}

img.imgPadding {
  width: 35px;
  height: 48px;
}

.row.p-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px;
}

.firstboxTp.checkBetAPi.text-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: black;
  color: white;
  /* padding: 20px; */
  padding-top: 10px;
  font-size: 18px;
  width: 48%;
  font-weight: 700;
  height: 95px;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
}

.Secondboxtp.text-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: black;
  color: white;
  /* padding: 20px; */
  padding-top: 10px;
  font-size: 18px;
  width: 48%;
  font-weight: 700;
  height: 95px;
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
}

.new-bet-flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.recent-res.teenpatti {
  background: #000;
  padding: 5px;
  color: #fff;
  /* margin-top: 50px; */
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 100px;
}

ul.result {
  display: flex;
  justify-content: space-around;
}

li.casino-a {
  background: #72bbef;
  /* padding: 5px;
    border-radius: 15px;
    width: 35px;
    display: inline-block;
    text-align: center;
    color: black;
    font-weight: 700;
    margin: 0 5px;
    margin-left: 10px; */
}
li.casino-b {
  background: #f9a9ba;
  /* padding: 5px;
    border-radius: 15px;
    width: 35px;
    display: inline-block;
    text-align: center;
    color: black;
    font-weight: 700;
    margin: 0 5px;
    margin-left: 10px; */
}

ul.result li {
  width: 35px;
  color: #333;
  margin-left: 10px;
  padding: 5px;
  list-style: none;
  text-align: center;
  font-weight: 700;
  display: inline-block;
  border-radius: 15px;
  font-size: 12px;
}

li.tigerResult {
  background: #ff4500;
}

li.loinResult {
  background: #ffff33;
}

.row-2.p-2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
}

.new-card-flex p {
  font-weight: bold;
  font-size: 12px;
}

.dragonboxTp.text-center {
  display: flex;
  background: black;
  color: white;
  height: 70px;
  width: 138px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.tieboxTp.text-center {
  display: flex;
  background: black;
  color: white;
  height: 70px;
  width: 64px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.tigerboxtp.text-center {
  display: flex;
  background: black;
  color: white;
  height: 70px;
  width: 138px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.dragonboxTp.text-center.fl7 {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #006cd1, #2c3e50);
  color: white;
  height: 87px;
  width: 150px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.tigerboxtp.text-center.fl7 {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #006cd1, #2c3e50);
  color: white;
  height: 87px;
  width: 150px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

li.l7.casino-c {
  background: #ffff33;
}

.threecardjbox.back {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-content: center;
}
.threecardjbox.lay {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-content: center;
}

.img-div img {
  width: 30px;
  height: 38px;
  margin: 3px;
}

.threecard-title {
  width: 10%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  font-weight: 700;
  flex-direction: column;
  border: 1px solid #111010;
  border-right: 0;
  border-bottom: 0;
  padding: 0 22px;
}

.threecardj-cards {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10px;
  border: 1px solid #111010;
  border-bottom: 0;
  flex-direction: column;
}

.back {
  background-color: #72bbef !important;
}

.lay {
  background-color: #f9c9d4 !important;
}

.card-odd-box-new {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

marquee {
  font-size: 12px;
}

.card-odd-box-new-2 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

b.f-16 {
  font-size: 24px;
  color: #333;
}

span.f-16 {
  font-size: 12px;
}

/* .col-span-12.mt-\[5px\].bg-\[\#000\].p-\[5px\].text-\[\#fff\].inline-block.overflow-x-auto.mb-\[100px\].whitespace-nowrap {
    display: none;
} */

ul.nav.nav-tabs {
  display: flex;
}

img.card-img {
  width: 30px;
  height: 42px;
  margin: 0 2px;
}

li.nav-item.col {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  /* padding: 0px 15px; */
}

/* a.nav-link.game {
    padding: 0 1px;
}
a.nav-link.match {
    padding: 0 1px;
} */

ul.nav.nav-tabs-casino.game-nav-bar.menu-list-casino {
  display: flex;
  justify-content: space-around;
}

li.nav-item.casinoname-item.text-center.aaa {
  /* background: azure; */
  /* padding: 7px; */
  color: black;
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 40px;
  border-right: 1px solid black;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

div#home {
  background: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
}

/* a.nav-link {
    padding: 33px;
} */

.main-winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 14px gray;
  /* margin-top: 10px; */
  margin: 10px 0;
}

.winner-text span,
.box-winner span {
  font-size: 20px;
  font-weight: 700;
}

.box-winner {
  background: #a7d8fd !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 7px gray;
  margin: 20px 0;
  font-size: 16px;
  padding: 2px;
  width: 105px;
}

.box-winner p {
  font-size: 10px;
}

.black-red {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 14px gray;
  /* margin-top: 10px; */
  margin: 10px 0;
}

.black-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.black-content span {
  font-weight: bold;
}

.red-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.red-content span {
  font-weight: bold;
}

.black-red-heading {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.box-winner-new {
  background: #a7d8fd !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 7px gray;
  margin: 20px 0;
  font-size: 16px;
  padding: 2px;
  width: 70px;
  height: 40px;
  justify-content: center;
}

.sub-winner {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.new-video {
  position: absolute;
  height: auto;
  background: rgba(0, _0, _0, _.4);
  left: 0;
  padding: 3px;
}

/* .col-span-12.relative.flex {
    margin-top: 56px;
} */

.card {
  border: 1px solid;
  /* display: flex; */
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
}

span.btn.btn-primary.card-header.inner-sportbook {
  background-image: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  width: 100%;
  display: block;
  padding: 0 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-top: 2px;
}

table.table.table-hover.tbl-bets.p-rltv.sportbook-market-tbl {
  width: 100%;
}

dl#minMaxBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #a6c0d1;
  border-radius: 5px;
  padding: 0px 10px;
  color: #333;
}

th.min-max-mobile.p-1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.worli-top {
  display: flex;
}

.worli-down {
  display: flex;
}

.worli-odd-box.back.gy-1 {
  /* padding: 10px; */
  height: 70px;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
}

span.worli-odd {
  font-family: CasinoNumFont;
  font-size: 30px;
  display: grid;
  text-align: center;
}

span.worli-odd {
  font-family: CasinoNumFont;
  font-size: 30px;
  color: black;
}

.worli-box-row1 {
  display: flex;
}

span.d-flex.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.worli-odd-box.back.spn {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 2px 3px;
}
span.d-block {
  color: black;
}

span.span-name {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-wrap-mode: nowrap;
  z-index: 1;
  position: relative;
}

a.d-flex.flex-column.p-0.align-items-center.back {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 105px !important;
  border-radius: 5px;
  padding: 2px 0;
  box-shadow: 0 2px 7px 1px #67828be6;
  margin: 0 2px;
  font-size: 14px;
  background: #72bbef !important;
}
a.d-flex.flex-column.p-0.align-items-center.lay {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 105px !important;
  border-radius: 5px;
  padding: 2px 0;
  box-shadow: 0 2px 7px 1px #67828be6;
  margin: 0 2px;
  font-size: 14px;
  background: #faa9ba80 !important;
}

.d-flex {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.inner-bollywood {
  display: flex;
  align-items: center;
  padding: 10px 4px;
}

.main-bollywood {
  background: linear-gradient(
    90deg,
    rgb(153 199 241) 0%,
    rgb(138 189 216 / 50%) 49%,
    rgb(146 198 246) 100%
  );
}

img.A-svg {
  width: 34px;
  height: 34px;
}

.submain-bollywood {
  border-bottom: 1px solid white;
}

.color-name {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.black-color {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #72bbef;
  width: 160px;
  height: 68px;
  border-radius: 50px;
}

.red-color {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #72bbef;
  width: 160px;
  height: 68px;
  border-radius: 50px;
}

.red-color span,
.black-color span {
  font-size: 30px;
}

img.card-image {
  width: 34px;
  height: 48px;
  margin: 0 4px;
}

.card-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  padding: 15px;
  margin-bottom: 10px;
}

.inside-content {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid gray;
}
.back-lay {
  display: flex;
}
.back-1.back-price {
  background: #72bbef !important;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.lay-1.lay-price {
  background: #f9c9d4 !important;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.min-max {
  background: #ddd;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin: 2px;
  padding: 4px;
  border-radius: 5px;
}

.match-name {
  width: 100%;
  padding: 10px 0px 10px 4px;
  font-size: 14px;
  font-weight: bold;
}

.back-1.back-rate {
  background: #72bbef !important;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  font-size: 14px;
  flex-direction: column;
  line-height: 1;
}

.lay-1.lay-rate {
  background: #f9c9d4 !important;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  font-size: 14px;
  flex-direction: column;
  line-height: 1;
}

.three-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.first-heading {
  background-image: linear-gradient(-180deg, #f6a21e 0, #cb7e05 100%);
  width: 100%;
  display: block;
  padding: 0 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-top: 2px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.odd-in-red {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-180deg, #af2736 0, #93212d 100%);
  font-size: 12px;
  color: white;
  font-weight: 700;
  height: 70px;
}
.even-in-blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-180deg, #1285e2 0, #0b5795 100%);
  font-size: 12px;
  color: white;
  font-weight: 700;
  height: 70px;
}
.first-content {
  margin-right: 2px;
}

.min-max.min-max-new {
  justify-content: center;
  /* margin: 5px 0px;
    padding: 2px; */
}

.card-image img {
  width: 30px;
  height: 38px;
}

.inside-content-card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}
.card-image {
  margin-left: 4px;
}
.back-1.back-card-price {
  background: #72bbef !important;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.back-1.back-card-rate {
  background: #72bbef !important;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  font-size: 14px;
  flex-direction: column;
  line-height: 1;
}

.dtl-main {
  background: #2e3438;
}
a.nav-link {
  color: white;
  padding: 0px 35px;
}
a.nav-link.active {
  border-bottom: 3px solid #fdcf13 !important;
  background-color: transparent;
}

.winner {
  display: flex;
  justify-content: space-between;
  margin: 11px 0;
  background: #3c444b85;
  /* padding: 10px 0; */
}
.winner-text {
  color: #aaafb5;
  /* background: #495057; */
  /* width: 67%; */
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.winner-text span {
  font-size: 12px;
  color: #aaafb5;
}
.rate {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #72bbef;
  width: 120px;
  height: 36px;
  color: white;
  font-weight: 700;
  border: 2px solid #72bbef;
}
.sizefont {
  font-size: 10px;
  font-weight: 400;
}

/* new css */
.sub-dtl {
  /* margin-top: 23px; */
}

li.casino-d {
  background-color: #ff4500;
}

.random-box {
  background: linear-gradient(-180deg, #f4b501 0%, #f68700 100%);
  color: #000;
  width: 40px;
  height: 40px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/* General tab styling */
.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

/* Inactive tab styles */
.tab.inactive {
  background: linear-gradient(to right, #ff6700, #ff8500);
  color: black;
}

/* Active tab styles */
.tab.active {
  background: linear-gradient(360deg, #030a12, #444647 60%, #58595a);
  color: white;
}

.sub-content {
  border-bottom: 1px solid #333;
}

.cricket-banner-img {
  background: linear-gradient(0deg, #0000001a, #00000073),
    url("../public/other/cricketbanner.jpg");
  background-size: 100% 100px !important;
  background-repeat: no-repeat;
}

.tennis-banner-img {
  background: linear-gradient(0deg, #0000001a, #00000073),
    url("../public/other/TennisBanner.webp");
  background-size: 100% 100px !important;
  background-repeat: no-repeat;
}

.soccer-banner-img {
  background: linear-gradient(0deg, #0000001a, #00000073),
    url("../public/other/FootBallBanner.webp");
  background-size: 100% 100px !important;
  background-repeat: no-repeat;
}

.zoomtime {
  animation: 1s infinite zoomtime;
}

@keyframes zoomtime {
  0% {
    transform: scale(1);
    color: #fff;
  }
  50% {
    transform: scale(1.1);
    color: #ff0;
  }
  100% {
    transform: scale(1);
    color: #fff;
  }
}

.rulesCasino p {
  margin-bottom: 1rem;
  font-size: 12px;
}
